import { HttpClient, HttpParameterCodec, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CustomHttpParameterCodec } from "../encoder";
import { Configuration } from "../configuration";
import { format } from "date-fns";

export interface PressureAndTemperatureData {
    // timestamp_iso: any;
    timeStampISO: any;
    // timestamp: number;
    timeStamp: number;
    pressure: number;
    temperature: number;
}

@Injectable({
    providedIn: 'root',
})
export class PressureCapGraphService {
  
  public encoder: HttpParameterCodec;
  public configuration = new Configuration();

  constructor(private http: HttpClient) {
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
        httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
        httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
}

private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
        return httpParams;
    }

    if (typeof value === "object") {
        if (Array.isArray(value)) {
            (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
        } else if (value instanceof Date) {
            if (key != null) {
                if (value instanceof Date) {
                    let resDate = format(value, 'yyyy-MM-dd')
                    httpParams = httpParams.append(key, resDate);
                }
            } else {
               throw Error("key may not be null if value is Date");
            }
        } else {
            Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                httpParams, value[k], key != null ? `${key}.${k}` : k));
        }
    } else if (key != null) {
        httpParams = httpParams.append(key, value);
    } else {
        throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
}

getPressureAndTemperatureData(startDay: string, endDay: string, deviceId: string | null): Observable<PressureAndTemperatureData[]> {
    
    let localVarQueryParameters = new HttpParams({encoder: this.encoder});

    if (startDay !== undefined && startDay !== null) {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>startDay, 'startDay');
      }
      if (endDay !== undefined && endDay !== null) {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
          <any>endDay, 'endDay');
      }

    return this.http.get<Array<PressureAndTemperatureData>>('demo/v1/devices/' + deviceId + "/pressures", {
        params: localVarQueryParameters
    });
}

}