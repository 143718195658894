export function convertToDisplayValueTuple<T, K extends keyof T>(
    param: T,
): DisplayValueTuple<keyof T, T[K]>[] {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (Object.keys(param) as any).map((displayValue: K) => {
        return { displayValue, value: param[displayValue] };
    });
}

export type DisplayValueTuple<T, K> = {
    displayValue: T;
    value: K;
};

export enum DataSource {
    Estimated = 'ESTIMATED',
    Real = 'REAL',
    Manual = 'MANUAL'

}
