export enum Permission {
    /** Admin device listing permission */
    ADMIN_DEVICE_LISTING = 'P_A_G_DEV',
    /** Admin device status setting permission */
    ADMIN_DEVICE_STATUS_OVERWRITE = 'P_A_P_DEV',
    /** Admin installed address edit permission */
    ADMIN_EDIT_ADDRESS = 'P_A_P_ADDR',
    /** Admin alert confirming permission */
    ADMIN_DEVICE_ALERT_ACKNOWLEDGE = 'P_A_P_ACK',
    /** Admin critical alert confirming permission */
    ADMIN_DEVICE_CRITICAL_ALERT_ACKNOWLEDGE = 'P_A_P_CACK',
    /** Admin user listing permission */
    ADMIN_USERS_LISTING = 'P_A_G_USR',
    /** Admin permission setting on other users permission */
    ADMIN_SET_USERS_PERMISSIONS = 'P_A_PU_USR',
    /** Admin changing user details permission */
    ADMIN_SET_USERS_DETAILS = 'P_A_PO_USR',
    /** Device Deactivation permission */
    DEACTIVATION_PERMISSION = 'P_A_P_DEAC',
    /** Storekeeper getting devices list permission */
    STOREKEEPER_DEVICE_LISTING = 'P_S_G_LST',
    // /** Storekeeper getting device status permission */
    STOREKEEPER_DEVICE_STATUS_LISTING = 'P_S_G_DEV',
    // /** Storekeeper setting device status permission */
    STOREKEEPER_DEVICE_STATUS_OVERWRITE = 'P_S_P_DEV',
    /** Installer getting device information permission */
    INSTALLER_DEVICE_LISTING = 'P_I_G_DEV',
    /** Installer setting device information permission */
    INSTALLER_DEVICE_DETAILS_OVERWRITE = 'P_I_P_DEV',
    /** Device messages view permission */
    ENGINEERING_UI_ACCESS_PERMISSION = 'P_E_P_USR',
    /** ImpCap Installation view permission */
    IMP_CAP_INSTALLATION_VIEW = 'I_I_V_DEV',
    /** ImpCap Installation recording permission */
    IMP_CAP_INSTALLATION_RECORDING = 'I_I_R_DEV',
    /** Pressure Cap view permission */
    PRESSURE_CAP_VIEW = 'P_P_C_V',
}
